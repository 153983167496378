import PropTypes from 'prop-types';
import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';

export default function Testimonials({ data }) {
  if (data && data.length === 0) {
    return null;
  }
  return (
    <section
      id="testimonials"
      className="relative z-10 px-5 py-10 overflow-hidden bg-white md:py-20"
    >
      <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-7xl text-brand-main">
        <div className="flex flex-col items-center justify-center text-center">
          <h2
            data-aos="fade-up"
            data-aos-delay="200"
            className="mb-4 text-2xl md:mb-8 md:text-4xl payfair text-brand-secondary"
          >
            Testimonios de Éxito
          </h2>
          <p data-aos="fade-up" data-aos-delay="400" className="text-sm md:text-base max-w-prose ">
            Descubre cómo personas como tú han transformado sus vidas gracias a su trabajo con Julie
            Ann. Estas historias reales subrayan el impacto positivo de nuestra colaboración y el
            progreso alcanzado hacia metas personales y profesionales.
          </p>
        </div>
        <div className="grid-cols-1 gap-20 mx-auto mt-10 md:mt-20">
          <div className="relative flex items-center ">
            <div
              className="absolute z-50 hidden w-full h-full pointer-events-none md:block "
              style={{
                background:
                  'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(140,140,140,0) 10%, rgba(228,228,228,0) 90%, rgba(255,255,255,1) 100%)',
              }}
            />
            <Swiper
              slidesPerView={2}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
              }}
              spaceBetween={20}
              centeredSlides
              autoplay={{
                delay: 9000,
                disableOnInteraction: true,
              }}
              grabCursor
              modules={[Autoplay]}
              data-aos="fade-right"
              data-aos-delay="300"
              className="!h-full gap-10  flex items-center"
            >
              {data.map((item) => (
                <SwiperSlide
                  key={item.name}
                  className="p-10  my-auto  border border-l-[5px] !h-fit border-brand-main rounded-3xl"
                >
                  <p className="text-sm select-none md:text-base">{item.content}</p>
                  <h3 className="mt-4 text-xs font-semibold select-none md:text-sm md:mt-8 payfair text-brand-main">
                    {item.name}
                  </h3>
                  <p className="mt-4 text-xs select-none md:text-sm text-brand-main">
                    {item.company}
                  </p>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

Testimonials.propTypes = {
  data: PropTypes.array,
};
